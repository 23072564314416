import * as React from 'react'
import Layout from '../components/layout'

const ServicesPage = () => {
  return (
    <Layout pageTitle="Services">
      <div className="container mb-16">
        <p className="text-xl mb-5">
          We offer general contracting for both residential and commercial projects.
        </p>
        <ul className="text-xl list-disc ml-5">
        <li className="mb-3">
            New home construction
          </li>
          <li className="mb-3">
            Frame to finish carpentry
          </li>
          <li className="mb-3">
            Additions
          </li>
          <li className="mb-3">
            Garages
          </li>
          <li className="mb-3">
            Renovations/remodeling
          </li>
          <li className="mb-3">
            Lead-safe certified construction
          </li>
          <li className="mb-3">
            3/4-season porches
          </li>
          <li className="mb-3">
            Custom decks
          </li>
          <li className="mb-3">
            Foundation replacements
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default ServicesPage